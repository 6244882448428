<template>
	<div class="container">
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="value1"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="radio4" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
					<el-select 
					v-model="value" 
					placeholder="请选择"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top -->  
		<el-row >
		  <el-col :span="3">
				<div class="grid-content">
					<el-progress type="circle" :percentage="90" width="25" :show-text="false"></el-progress>
					<span>90%</span>
				</div>
				<p class="p-color">劳动业绩</p>
				<p>￥0</p>
			</el-col>
		  <el-col :span="3">
		  	<div class="grid-content">
		  		<el-progress type="circle" :percentage="90" width="25" :show-text="false"></el-progress>
		  		<span>90%</span>
		  	</div>
		  	<p class="p-color">商品销售业绩</p>
		  	<p>￥0</p>
		  </el-col>
		  <el-col :span="3">
				<div class="grid-content">
					<el-progress type="circle" :percentage="90" width="25" :show-text="false"></el-progress>
					<span>90%</span>
				</div>
				<p class="p-color">开充卡业绩</p>
				<p>￥0</p>
				<p class="p-color">开:0（含工本费:0）充:0</p>
		  </el-col>
			<el-col :span="3">
				<div class="grid-content">
					<el-progress type="circle" :percentage="90" width="25" :show-text="false"></el-progress>
					<span>90%</span>
				</div>
				<p class="p-color">疗程卡业绩</p>
				<p>￥0</p>
			</el-col>
			<el-col :span="3">
				<div class="grid-content">
					<el-progress type="circle" :percentage="90" width="25" :show-text="false"></el-progress>
					<span>90%</span>
				</div>
				<p class="p-color">会员管理费</p>
				<p>(卡工本费)</p>
				<p>￥0</p>
			</el-col>
			<el-col :span="3">
				<p class="p-color">总业绩</p>
				<p>￥0</p>
			</el-col>
			<el-col :span="3">
				<p class="p-color">劳动，商品业绩</p>
				<p>￥0</p>
			</el-col>
			<el-col :span="3">
				<p class="p-color">还款</p>
				<p>￥0</p>
			</el-col>
			<el-col :span="3">
				<p class="p-color">总现金</p>
				<p>￥0</p>
			</el-col>
		</el-row>
		<!-- top -->
		<div class="top bottom">
			<div class="left">
				<span>营业统计</span>
				<el-button size="mini" icon="el-icon-date"></el-button>
			</div>
			<div class="right">
				<span>总耗卡：0元</span>
				<span>总耗赠金：0元</span>
				<span>总疗程消耗：0元</span>
				<span>总疗程赠送：0元</span>
				<span>总欠款：0元</span>
			</div>
		</div><!-- end top -->  
		<!-- table -->
		<el-table
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}">
			<el-table-column
				prop="date"
				label="项目"
				width="150">
			</el-table-column>
			<el-table-column label="支付方式/元" align="center">
				<el-table-column
					prop="province"
					label="现金">
				</el-table-column>
				<el-table-column
					prop="city"
					label="银联">
				</el-table-column>
				<el-table-column
					prop="address"
					label="微信">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="支付宝">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="团购">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="现金券">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="耗卡">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="耗赠金">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="耗疗程卡">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="疗程赠送">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="欠款">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="耗积分">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="积分抵扣">
				</el-table-column>
			</el-table-column>
			<el-table-column label="汇总数据" align="center">
				<el-table-column
					prop="province"
					label="总现金">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="小计"
					width="202">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
		<!-- top -->
		<div class="top bottom">
			<div class="left">
				<span>客户统计</span>
				<el-button size="mini" icon="el-icon-date"></el-button>
				<i>（仅统计劳动业绩）</i>
			</div>
		</div><!-- end top -->
		<!-- table -->
		<el-table
			:data="tableData2"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}">
			<el-table-column label="整体分析" align="center">
				<el-table-column
					prop="province"
					label="服务单数">
				</el-table-column>
				<el-table-column
					prop="city"
					label="服务记客数">
				</el-table-column>
				<el-table-column
					prop="address"
					label="服务业绩">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="客单价">
				</el-table-column>
			</el-table-column>
			<el-table-column label="指定非指定分析" align="center">
				<el-table-column
					prop="province"
					label="指定客数">
				</el-table-column>
				<el-table-column
					prop="city"
					label="指定业绩">
				</el-table-column>
				<el-table-column
					prop="address"
					label="指定客单价">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="指定率">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="非指定客数">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="非指定业绩">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="非指定客单价">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="非指定率">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
		<div style="margin-top: 20px;"></div>
		<!-- table -->
		<el-table
			:data="tableData2"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}">
			<el-table-column label="会员散客分析" align="center">
				<el-table-column
					prop="province"
					label="会员客数">
				</el-table-column>
				<el-table-column
					prop="city"
					label="会员业绩">
				</el-table-column>
				<el-table-column
					prop="address"
					label="会员客单价">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="会员率">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="散客数">
				</el-table-column>
				<el-table-column
					prop="province"
					label="散客业绩">
				</el-table-column>
				<el-table-column
					prop="city"
					label="散客客单价">
				</el-table-column>
				<el-table-column
					prop="address"
					label="散客率">
				</el-table-column>
			</el-table-column>
			<el-table-column label="男女客分析" align="center">
				<el-table-column
					prop="province"
					label="女客数">
				</el-table-column>
				<el-table-column
					prop="city"
					label="女客业绩">
				</el-table-column>
				<el-table-column
					prop="address"
					label="女客客单价">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="女客率">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="男客数">
				</el-table-column>
				<el-table-column
					prop="province"
					label="男客业绩">
				</el-table-column>
				<el-table-column
					prop="city"
					label="男客客单价">
				</el-table-column>
				<el-table-column
					prop="address"
					label="男客率">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
		<!-- top -->
		<div class="top bottom">
			<div class="left">
				<span>收支统计</span>
				<i>（不区分部门）</i>
			</div>
		</div><!-- end top -->
		<el-table
			:data="tableData"
			style="width: 100%"
			border
			:header-cell-style="{color:'#475669' , background:'#f5f7fa'}">
			<el-table-column
				prop="id"
				label="支付方式">
			</el-table-column>
			<el-table-column
				prop="id"
				label="营业收入">
			</el-table-column>
			<el-table-column
				prop="id"
				label="其他收入">
			</el-table-column>
			<el-table-column
				prop="id"
				label="支出">
			</el-table-column>
			<el-table-column
				prop="id"
				label="资金结余">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data (){
			return {
				radio4: '今日',
				value1: '',
				tableData: [{
					id: '全店',
					date: '劳动业绩',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '商品销售业绩',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '开充卡业绩',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '疗程卡业绩',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '还欠款',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				}],
				tableData2: [{
					id: '全店',
					date: '劳动业绩',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				}]
			}
		},
		methods:{
		}
		
	}
</script>

<style lang="less" scoped>
.container{
	padding-bottom: 20px;
}	
	
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.left{
		display: flex;
		align-items: center;
		.el-select{
			width: 250px;
			margin-right: 10px;
		}
		.select{
			display: flex;
			align-items: center;
			.el-date-picker{
				width: 310px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	.el-button{
		margin-left: 10px;
	}
}
.bottom{
	margin-bottom: 20px;
	margin-top: 40px !important;
}

.el-row{
	height: 150px;
	//border: 1px solid #c0ccda;
	margin: 20px 0;
	border-radius: 5px;
	.el-col{
		height: 100%;
		border: 1px solid #c0ccda;
		border-right: none ;
		padding: 10px;
		.grid-content{
			display: flex;
			align-items: center;
			span{
				margin-left: 10px;
			}
		}
		.p-color{
			color: #8492a6;
		}
	}
	.el-col-3{
		width: 10.5% !important;
	}
	.el-col:nth-child(1),
	.el-col:nth-child(2),
	.el-col:nth-child(3),
	.el-col:nth-child(4),
	.el-col:nth-child(5),
	.el-col:nth-child(6),
	.el-col:nth-child(7){
		width: 11.14% !important;
	}
	.el-col:nth-child(6),
	.el-col:nth-child(7){
		background-color: #20a0ff;
		color: #FFFFFF;
		.p-color{
			color: #FFFFFF;
			
		}
	}
	.el-col:nth-child(8){
		margin-left: 1%;
		border-left: 1px solid  #c0ccda;
	}
	
	/* .el-col:nth-child(9){
		width: 13.25% !important;
	} */
	.el-col:last-child{
		border-right: 0;
		background-color: #07c160;
		color: #FFFFFF;
		.p-color{
			color: #FFFFFF;
		}
	}
}

</style>
